import Glide from '@glidejs/glide'
import $ from 'cash-dom'

export default $(function () {
    const glideElement = $('.slidePanel').get(0)

    if (!glideElement) {
        return
    }
    const sliders = document.querySelectorAll('.slidePanel')

    sliders.forEach((slider) => {
        new Glide(slider as unknown as string, {
            type: 'carousel',
            startAt: 0,
            perView: 4,
            bound: true,
            gap: 40,
            autoplay: 3000,
            animationDuration: 1000,
            rewindDuration: 1000,
            animationTimingFunc: 'ease-in-out',
            breakpoints: {
                800: {
                    perView: 3,
                },
                500: {
                    perView: 1,
                },
            },
        }).mount()
    })
})
