import $ from 'cash-dom'
import type { SweetAlertOptions } from 'sweetalert2'
import Swal from 'sweetalert2'

export const modalProps: SweetAlertOptions = {
    showCloseButton: false,
    showConfirmButton: false,
    loaderHtml: '',
    backdrop: `rgba(0,0,0,0)`,
    allowEscapeKey: true,
    allowOutsideClick: true,
    stopKeydownPropagation: false,
    customClass: {
        container: 'modal-container',
        popup: 'modal-popup',
        title: 'modal-title',
        closeButton: 'modal-closeButton',
        icon: 'modal-icon',
        image: 'modal-image',
        htmlContainer: 'modal-htmlContainer',
        input: 'modal-input',
        inputLabel: 'modal-inputLabel',
        validationMessage: 'modal-validationMessage',
        actions: 'modal-actions',
        confirmButton: 'modal-confirmButton',
        denyButton: 'modal-denyButton',
        cancelButton: 'modal-cancelButton',
        loader: 'modal-loader',
        footer: 'modal-footer',
    },
}

export default $(function () {
    $('.tease-work').on('click', function () {
        const key = Number((this as HTMLDivElement).getAttribute('data-key'))

        openModalAtKey(key)
    })

    document.addEventListener('keydown', (e) => {
        if (e.code === 'ArrowLeft') {
            if ($('.modal--back').length > 0 && openModalKey > 0) {
                return navigateBack()
            }
        }

        if (e.code === 'ArrowRight') {
            if ($('.modal--forward').length > 0) {
                return navigateForward()
            }
        }
    })
})

let openModalKey: number = 0

const openModalAtKey = (key: number) => {
    const modalHtml = $(`#modal-content-${key}`).html()
    const modalFooter = $(`#modal-footer-${key}`).html()

    if (window.innerWidth < 1120) {
        return
    }

    Swal.fire({
        ...modalProps,
        progressSteps: [],
        html: modalHtml,
        footer: modalFooter,
        didClose: () => {
            openModalKey = null
        },
        willClose: async () => {
            $('body').removeClass('swal2-shown')
            $('html').removeClass('swal2-shown')
        },
        didRender: () => {
            $('.modal--back').on('click', async () => {
                await navigateBack()
            })
            $('.modal--forward').on('click', async () => {
                await navigateForward()
            })
        },
    })

    openModalKey = key
}

export async function navigateBack() {
    $('.modal-container').addClass('modal__navigating')
    await wait(300)

    if (openModalKey === 0) {
        openModalAtKey($(`.modal-content--work`).last().data('key'))
    } else {
        openModalAtKey(openModalKey - 1)
    }
}

export async function navigateForward() {
    $('.modal-container').addClass('modal__navigating')
    await wait(300)

    if ($(`#modal-content-${openModalKey + 1}`).get(0)) {
        openModalAtKey(openModalKey + 1)
    } else {
        openModalAtKey(0)
    }
}

export function wait(timeout: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve()
        }, timeout)
    })
}
