import Glide from '@glidejs/glide'
import $ from 'cash-dom'

export default $(function () {
    const glideElement = $('.slider').get(0)

    if (!glideElement) {
        return
    }

    new Glide('.slider', {
        animationDuration: 0,
    }).mount()
})
