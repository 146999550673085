import $ from 'cash-dom'

export default $(function () {
    $('.subscribe--link').on('click', (e) => {
        openForm()
    })

    $('[href="#subscribe-form"]').on('click', (e) => {
        openForm()
    })

    $('.subscribe-form--toggle').on('click', (e) => {
        if ($('.subscribe-form--toggle').text().includes('Subscribe')) {
            openForm()
        } else {
            closeForm()
        }

        return false
    })
})

const openForm = () => {
    $('.subscribe-form--form').addClass('subscribe-form__open')
    $('.subscribe-form--toggle').text('Cancel')
}
const closeForm = () => {
    $('.subscribe-form--form').removeClass('subscribe-form__open')
    $('.subscribe-form--toggle').text('Subscribe')
}
