import $ from 'cash-dom'

const hoverClass = 'menu-item__nofocus'

export default $(function () {
    $('.menu-item')
        .children('a')
        .on('mouseover', function () {
            $('.menu-item').not($(this).parent()).addClass(hoverClass)
        })

    $('.menu-item')
        .children('a')
        .on('mouseout', function () {
            $('.menu-item').not($(this).parent()).removeClass(hoverClass)
        })
})
