import Glide from '@glidejs/glide'
import $ from 'cash-dom'

export default $(function () {
    const glideElement = $('.gallery').get(0)

    if (!glideElement) {
        return
    }

    new Glide('.gallery', {
        type: 'carousel',
        startAt: 0,
        perView: 2,
        peek: { after: 100, before: 0 },
        bound: true,
        autoplay: 3000,
        animationDuration: 1000,
        rewindDuration: 1000,
        animationTimingFunc: 'ease-in-out',
        breakpoints: {
            800: {
                perView: 2,
            },
            500: {
                perView: 1,
                peek: 0,
            },
        },
    }).mount()
})
