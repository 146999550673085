import $ from 'cash-dom'

const stickyElm = document.querySelector('.header')

export default $(function () {
    const observer = new IntersectionObserver(
        ([e]) =>
            e.target.classList.toggle(
                'header__sticky',
                e.intersectionRatio < 1,
            ),
        { threshold: [1] },
    )

    observer.observe(stickyElm)
})
