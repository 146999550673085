import $ from 'cash-dom'

export default $(function () {
    $('.exhibition-archive .exhibition-archive--exhibition').addClass(
        'exhibition__hidden',
    )

    const latestYear = $('.exhibition-archive--selector--link')
        .first()
        .addClass('selector--link__active')
        .get(0)
        ?.getAttribute('data-value')

    addClassToYear(latestYear)

    $('.exhibition-archive--selector--link').on('click', (e: PointerEvent) => {
        const target: HTMLAnchorElement = e.currentTarget as HTMLAnchorElement

        $('.exhibition-archive--selector--link').removeClass(
            'selector--link__active',
        )
        $(target).addClass('selector--link__active')

        const selectedYear = target.getAttribute('data-value')

        addClassToYear(selectedYear)

        return false
    })
})

function addClassToYear(year: string) {
    $(`.exhibition-archive--exhibition`).removeClass('exhibition__active')
    $(`.exhibition-archive--exhibition[data-year="${year}"]`).addClass(
        'exhibition__active',
    )
}
