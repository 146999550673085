import $ from 'cash-dom'

export default $(function () {
    $('.navigation-toggle').on('click', () => {
        $('.navigation-toggle').toggleClass('navigation-toggle__open')
        $('body').toggleClass('navigation__open')
    })
    $('[href="#subscribe-form"]').on('click', (e) => {
        $('.navigation-toggle').removeClass('navigation-toggle__open')
        $('body').removeClass('navigation__open')
    })
})
