import $ from 'cash-dom'

export default $(function () {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault()
            if (!$(this.getAttribute('href'))) {
                return true
            }

            $(this.getAttribute('href')).get(0)?.scrollIntoView({
                behavior: 'smooth',
            })
        })
    })
})
